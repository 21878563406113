
import {Component, Emit, Inject, Model, Prop, Provide, Vue, Watch} from 'vue-property-decorator';
import BackButton from '@/views/module/common/BackButton.vue';

import ApplyHandleType from '@/platform/wap/view/data/ApplyHandleType';
import groupHandlerTab from '@/platform/wap/view/data/GroupHandlerTab';

import GroupJoinApplyListPane from '@/views/module/notice/GroupJoinApplyListPane.vue';
import GroupInviteApplyListPane from '@/views/module/notice/GroupInviteApplyListPane.vue';
import GroupInviteeApplyListPane from '@/views/module/notice/GroupInviteeApplyListPane.vue';


@Component({
    components: {
        BackButton,
        GroupJoinApplyListPane,
        GroupInviteApplyListPane,
        GroupInviteeApplyListPane,
    },
})
export default class GroupHandlerTabPane extends Vue {
    private type = ApplyHandleType;
    private model = groupHandlerTab;

    public mounted() {
        // no
    }
}
